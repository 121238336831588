import React from "react"
import { FaTwitter, FaDiscord, FaMedium } from "react-icons/fa"

export const mainMenuItems = [
  {
    path: "/",
    title: "home",
  },
  {
    path: "/about",
    title: "about",
  },
  {
    path: "/contact",
    title: "contact",
  },
]

export const socialMenuItems = [
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/jetprotocol",
    name: "Twitter",
  },
  {
    icon: <FaDiscord />,
    url: "https://discord.gg/RW2hsqwfej",
    name: "Discord",
  },
  {
    icon: <FaMedium />,
    url: "https://medium.com/jetprotocol",
    name: "Medium",
  },
]

// export const footerMenuItems = [
//   {
//     path: "/privacy",
//     title: "privacy",
//   },
//   {
//     path: "/cookies",
//     title: "cookies",
//   },
// ]
